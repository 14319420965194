import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  EventHeader,
  Speakers,
  HubFooter,
  Agendas,
  Faq,
  ProtectedRoute,
  SEO,
  VideoCarousel
} from 'components';
import { LocalContext } from 'context';
import { useStaticQuery, graphql } from 'gatsby';
import { H5, fullGridContainer, gridContainer, breakpoints } from 'styles';
import { motion } from 'framer-motion';

const LivestreamTemplate = ({ data }) => {
  const { frontmatter: event } = data.eventData;
  const { carouselVideoData } = data;
  const { setSelectedEvent, theme, registerRef, faqRef } = useContext(LocalContext);

  useEffect(() => {
    setSelectedEvent({
      name: event.name,
      colors: event.colors,
      slug: event.slug
    });
    return () => setSelectedEvent(null);
  }, [event.name]);

  return (
    <ProtectedRoute>
      <SEO
        pageSpecificTitle={`${event.name} Livestream`}
        pageSpecificDescription={event.description}
        pageSpecificThumbnail={event.seoThumbnail}
        pageSpecificThumbnailAlt={event.name}
      />
      <PageWrapper
        $theme={theme}
        key={event.eventId}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.5, duration: 1 }}>
        <EventHeader
          ref={registerRef}
          colors={event.colors}
          eventDates={event.dates}
          eventName={event.name}
          eventLocation={event.location}
          eventStartTime={event.startTime}
          eventEndTime={event.endTime}
          eventDescription={event.description}
          eventStreamPlaceholderImg={event.streamPlaceholderImg}
          showStreamPlaceHolderImg={event.showStreamPlaceHolderImg}
          eventStreamUrl={event.streamUrl}
          chatEnabled={event.chatEnabled}
          footNote={event.streamFootNote}
        />
        <ZoomLinkWrapper>
          <ZoomLink
            colors={event.colors}
            href="https://us02web.zoom.us/j/85936711595"
            target="_blank">
            Breakout Rooms
          </ZoomLink>
        </ZoomLinkWrapper>
        <Container $theme={theme}>
          <H5 style={{ textAlign: 'center' }}>Our Keynote Speakers</H5>
          <Speakers speakers={event.speakers} colors={event.colors} />
        </Container>
        <VideoCarousel carouselVideos={carouselVideoData.nodes.map((node) => node.frontmatter)} />
        {/* <Container $theme={theme} ref={faqRef}>
          <H5>Frequently Asked Questions</H5>
          <Faq />
        </Container> */}
        {/* <EventFooter /> */}
        <HubFooter />
      </PageWrapper>
    </ProtectedRoute>
  );
};

const ZoomLinkWrapper = styled.div`
  background-color: rgb(239, 237, 245);
  grid-column: 1/7;
  width: 100%;

  ${breakpoints.lg} {
    grid-column: 1/13;
  }
`;

const ZoomLink = styled.a`
  align-items: center;
  background-color: ${({ colors }) => colors.tertiary};
  color: #fff;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  height: 68px;
  justify-content: center;
  margin: 0 auto 2rem;
  padding: 1rem 0.5rem;
  text-transform: uppercase;
  width: 210px;

  @media screen and (min-width: 1133px) {
    margin: 2rem auto;
  }
`;

const Container = styled.section`
  ${gridContainer}
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
  ${H5} {
    color: ${({ $theme }) => $theme.textColor};
  }
  p {
    color: ${({ $theme }) => $theme.textColor};
  }
  ${breakpoints.sm} {
    padding: 6rem 1.25rem;
  }
  ${breakpoints.lg} {
    padding: 6rem 0;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
    padding: 6rem 0;
  }
`;

const FullWidthContainer = styled.section`
  ${fullGridContainer}
  background-color:${(props) => (props.bg ? props.bg : 'initial')};
  padding: 3.75rem 1.25rem;
  ${breakpoints.sm} {
    padding: 6rem 1.25rem;
  }
  ${breakpoints.xl} {
    padding: 6rem 0;
  }
`;

const PageWrapper = styled(motion.div)`
  background: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
  box-sizing: border-box;
  display: grid;
  filter: ${({ $theme }) => ($theme.className === 'grayscale' ? 'grayscale(100%)' : 'none')};
  grid-column: 1/7;
  grid-column-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);

  ${breakpoints.lg} {
    grid-column: 1/13;
    grid-column-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const query = graphql`
  query ($slug: String!) {
    eventData: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        eventId
        name
        slug
        status
        slug
        status
        colors {
          primary
          secondary
          tertiary
        }
        name
        title
        subtitle
        seoThumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        description
        dates
        startTime
        endTime
        streamUrl
        eventHighlights {
          id
          url
          thumb {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          title
          description
        }
        bannerVideo
        speakers {
          id
          name
          position
          fbLink
          twLink
          linkedinLink
          emailLink
          topics
          summary
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        agendas {
          day
          title
          slots {
            orderNumber
            timeSlot
            title
            speaker
          }
        }
      }
    }
    carouselVideoData: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/carousel-videos/livestream-page/" } }
      sort: { fields: [frontmatter___id] }
    ) {
      nodes {
        frontmatter {
          id
          title
          url
          thumb {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                formats: [AUTO, WEBP, AVIF]
                width: 1162
                height: 654
                placeholder: TRACED_SVG
              )
            }
          }
        }
      }
    }
  }
`;

export default LivestreamTemplate;
